import Vue from 'vue'
import App from './App.vue'
import Srv from '@/__shared/utils/lib_srv'
import VueMeta from 'vue-meta'
import router from './router'
import vuetifyPlugin from '@/admin/admin/plugin_vuetify'
const { vuetify } = vuetifyPlugin

Vue.config.productionTip = false
Vue.use(Srv)
Vue.use(VueMeta, { refreshOnceOnNavigation: true })

new Vue({
	vuetify,
	router,
	render: (h) => h(App),
}).$mount('#app')
