/* AUTO-GENERATED FILE - DO NOT EDIT */

/* vue-src/admin-login/components.js */

import Vue from 'vue'

export const Forgot = require('./view_Forgot.vue').default
export const Login = require('./view_Login.vue').default

const c = []
const r = (a,b) => {
	c.push(a)
	Vue.component(a,b)
}
export const componentExists = (name) => c.includes(name)
export const getComponentsNames = () => [...c]
export const filterComponents = (re) => c.filter(name => name.match(re))
r('Media', require('./../__shared/media-comps/comp_Media.vue').default)
r('RatioBox', require('./../__shared/media-comps/comp_RatioBox.vue').default)
r('RatioImage', require('./../__shared/media-comps/comp_RatioImage.vue').default)