import Vue from 'vue'
import Router from 'vue-router'
import { Login, Forgot } from '@/admin-login/autoloader'

Vue.use(Router)

const router = new Router({
	mode: 'history',
	base: process.env.VUE_APP_ADMIN_BASE_URL,
	routes: [
		{
			path: '/login',
			name: 'login',
			component: Login,
		},
		{
			path: '/login/forgot',
			name: 'forgot',
			component: Forgot,
		},
	],
})

router.beforeEach((to, from, next) => (to.path.endsWith('/') ? next(to.path.replace(/\/+$/, '')) : next()))

export default router
