<script>
import Api from '@/__shared/utils/lib_api'
import defaultLogoImage from '@/admin/admin/asset_logo.png'

export default {
	name: 'Forgot',
	props: {
		msg: String,
	},
	metaInfo() {
		return { title: 'Recuperar contraseña | Admin' }
	},
	data: () => ({
		valid: false,
		email: '',
		username: '',
		emailRules: [
			(v) => !!v || 'Debes introducir un email',
			(v) => /.+@.+/.test(v) || 'Debes usar un email valido',
		],
		usernameRules: [(v) => !!v || 'Debes introducir tu usuario'],
		isBtnLoading: false,
		formMsg: null,
		isSuccess: false,
	}),
	computed: {
		logoImage() {
			return this.$srv('AdminTheme.logo', defaultLogoImage)
		},
	},
	methods: {
		async submitForm() {
			let isValidated = this.$refs.forgotPassForm.validate()
			if (!isValidated) return
			let api = new Api({
				baseUrl: `${process.env.VUE_APP_ADMIN_BASE_URL}`,
			})
			await api.post('/forgot-pass', {
				data: { email: this.email, username: this.username },
				loading: (v) => (this.isBtnLoading = v),
				onSuccess: () => {
					this.isSuccess = true
					this.formMsg = 'Te hemos enviado un mail con los pasos para recuperar tu contraseña'
				},
			})
		},
	},
}
</script>

<template>
	<v-card width="400" max-width="90%" class="pa-4">
		<Media :src="logoImage" width="100%" max-height="60" />
		<v-card-title class="d-flex justify-center"> Recuperar contraseña </v-card-title>
		<v-card-text v-if="formMsg">
			<div class="text-center" :class="isSuccess ? 'success--text' : 'error--text'">
				{{ formMsg }}
			</div>
		</v-card-text>
		<v-card-text>
			<v-form
				ref="forgotPassForm"
				lazy-validation
				v-model="valid"
				@submit.prevent="submitForm"
				v-if="!isSuccess"
			>
				<v-row dense>
					<v-col align="center" cols="12">
						<v-text-field v-model="username" :rules="usernameRules" label="Usuario" />
					</v-col>
					<v-col align="center" cols="12">
						<v-text-field v-model="email" :rules="emailRules" label="Email" />
					</v-col>
					<v-col align="center" cols="12">
						<v-btn color="primary" :disabled="!valid" type="submit" :loading="isBtnLoading">
							Enviar
						</v-btn>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>
		<v-card-text class="text-center">
			<router-link :to="{ name: 'login' }"> Volver </router-link>
		</v-card-text>
	</v-card>
</template>
